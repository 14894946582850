import React from "react"
import TandCs from "./TandCs"

import "./LoginForm.css"

export default class LoginForm extends React.Component {
  state = {
    username: "",
    password: "",
    hasAgreetWithTandC: false,
    error: false,
  }

  isEnabled = () => {
    return (
      this.state.username.length > 3 &&
      this.state.password.length > 3 &&
      this.state.hasAgreetWithTandC
    )
  }

  handleUsernameChange = event => {
    this.setState({
      username: event.target.value,
    })
  }

  handlePasswordChange = event => {
    this.setState({
      password: event.target.value,
    })
  }

  handleAgreeWithTandCs = () => {
    return this.setState({
      hasAgreetWithTandC: !this.state.hasAgreetWithTandC,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    if (!this.isEnabled()) {
      return
    }
    window.location = "/home"
  }

  render() {
    return (
      <React.Fragment>
        <div className="wrapper">
          <h2>Login</h2>
          <form onSubmit={this.handleSubmit} className="form">
            <div className="field">
              <input
                type="text"
                name="username"
                placeholder="Username"
                value={this.state.username}
                onChange={this.handleUsernameChange}
                className="input"
              />
            </div>
            <div className="field">
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.handlePasswordChange}
                className="input"
              />
            </div>
            <div className="field resetPasswordWrap">
              <p className="resetPassword">Did you forget your password?</p>
            </div>
            <button
              type="submit"
              className="button"
              disabled={!this.isEnabled()}
            >
              Submit
            </button>
          </form>
        </div>
        <div className="checkWrap">
          <input
            type="checkbox"
            checked={this.state.hasAgreetWithTandC}
            onChange={this.handleAgreeWithTandCs}
            className="checkbox"
          />
          <span>
            By ticking this box, you, the Customer, agree the following terms:
          </span>
        </div>
        <div className="tandcsWrap">
          <TandCs />
        </div>
        <div className="blankSpace"></div>
      </React.Fragment>
    )
  }
}
