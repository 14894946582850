import React from "react"

import "./TandCs.css"

const TandCs = () => (
  <div className="wrap">
    <div className="spacer"></div>
    <p className="p">
      <span>
        <span className="maj">A.</span>
        Appsbroker has created and is the licensor of certain software
        products and services, including hosted services related to
        market data (the &ldquo;
      </span>
      <strong>Services</strong>
      <span>&rdquo; as defined below).</span>
    </p>

    <p className="p">
      <span>
        <span className="maj">B.</span> The Customer wishes to{" "}
      </span>
      <strong>trial</strong>
      <span>
        {" "}
        such Services from Appsbroker, on the terms of this Agreement,
        subject to any limitations and restrictions imposed on Client by
        the providers of the market data.
      </span>
    </p>

    <p className="p">
      <span>
        <span className="maj">C.</span> This agreement has been
        abbreviated to allow immediate access to the Services.
      </span>
    </p>

    <div className="spacer"></div>

    <ol className="ol main">
      <li className="li boldBefore">
        <strong>Key Terms of this agreement</strong>
        <ol className="ol">
          <li className="li">
            <span>
              This contract is for X Weeks from the date at the front of
              this agreement. Access to the service will be removed at
              the end of the trial period.
            </span>
          </li>
          <li className="li">
            <span>
              Support from Appsbroker will be available during the trial
              period on a best efforts <br />
              basis - priority will be given to contracted clients
            </span>
          </li>
          <li className="li">
            <span>The trial will be Free of Charge </span>
          </li>
          <li className="li">
            <span>
              Access to the Service is granted to a limited number of
              users to allow the Client to assess functionality and
              content..
            </span>
          </li>
          <li className="li">
            <span>
              Due to rules imposed by the APA Providers, any Market Data
              accessed during the trial period must be used{" "}
            </span>
            <strong>for internal display purposes only</strong>
          </li>
          <li className="li">
            <span>
              Appsbroker reserves the right to immediately withdraw
              access should it become aware that the Client is using the
              data for any purpose other than internal display
            </span>
          </li>
          <li className="li">
            <span>
              A fair usage policy will be enforced and whilst no charges
              will be <br />
              made should the use exceed the set boundaries Appsbroker
              reserves the right to withdraw access to the service
            </span>
          </li>
          <li className="li">
            <span></span>
          </li>
          <li className="li">
            <span>
              Client will not, except as may be allowed by any mandatory
              applicable law which is incapable of exclusion, attempt to
              reverse compile, disassemble, reverse engineer or otherwise
              reduce to human-perceivable form all or any part of the
              Service
            </span>
          </li>
          <li className="li">
            <span>
              Appsbroker will comply with Schedule 2 (Security)
            </span>
          </li>
          <li className="li">
            <span>
              the Services are provided &ldquo;AS IS&rdquo; and all
              warranties, terms and other assurances, whether implied or
              express, arising under law or custom relating to the
              Services and the Data, including as to accuracy,
              suitability, timeliness, completeness, title, merchantable
              quality, fitness for purpose, satisfactory quality, are
              hereby excluded by Appsbroker. Appsbroker does not warrant
              or provide any assurance that the Services or the Data are
              or will be suitable for Client&rsquo;s needs or business
              purposes. Appsbroker and its Affiliates do not provide any
              recommendations or advice as to investment decisions and
              Client shall not rely on the Services or the Data as the
              basis of any investment decision or trading strategy.
              Client shall not use, or permit the use of, the Data as a
              submission or component to any benchmark or index (as those
              terms are defined in the Benchmark Regulation (Regulation
              (EU) 2016/1011). Appsbroker does not provide any guarantee
              or assurance that the distribution or licensing policies of
              Data Providers accord with applicable regulatory or legal
              requirements.
            </span>
          </li>
          <li className="li">
            <span>
              This Agreement and any related non-contractual matters
              shall be governed by the laws of England and the parties
              both agree to submit to the exclusive jurisdiction of the
              courts of England.
            </span>
          </li>
        </ol>
      </li>
    </ol>

    <div className="spacer"></div>
    <p className="p center">
      <strong>SCHEDULE 1</strong>
    </p>
    <p className="p center">
      <strong>Support </strong>
    </p>

    <ol className="ol original">
      <li className="li noNo">
        <ol className="ol main">
          <li className="li boldBefore">
            <strong>Appsbroker Obligations</strong>
            <ol className="ol">
              <li className="li">
                <span>
                  Appsbroker will use reasonable efforts to provide
                  support assistance and maintenance activities for the
                  Service, via portal (
                </span>
                <span>
                  <a href="https://www.google.com/url?q=http://support.appsbroker.com/&amp;sa=D&amp;ust=1539334458044000">
                    http://support.appsbroker.com
                  </a>
                </span>
                <span>) for Service affecting incidents. </span>
              </li>
              <li className="li">
                <span>
                  Support hours are with UK Business hours, defined as
                  8am to 6pm UK local time, Monday to Friday, excluding
                  UK public holidays
                </span>
              </li>
              <li className="li">
                <strong>Client Obligations</strong>
                <ol className="ol">
                  <li className="li">
                    <span>
                      Client will nominate a single point of contact who
                      will be notified of Service maintenance activities
                      and any associated downtime. The Service owner is
                      responsible for managing the impact of any system
                      changes within Client&rsquo;s organisation, such as
                      (but not limited to) gaining appropriate internal
                      IT change approvals.
                    </span>
                  </li>
                  <li className="li">
                    <span>
                      Client will provide first line support duties
                      through their existing support staff and will carry
                      out relevant issue diagnostics to support the
                      resolution of the issue by Appsbroker. It is
                      expected first line support personnel have used all
                      support materials supplied and will provide
                      training and guidance from end users. These include
                      (but are not limited to) support documentation,
                      troubleshooting guides and known issues logs. Where
                      applicable, a checklist of steps to follow prior to
                      logging a support call may be provided. Support
                      tickets should include, where applicable, detailed
                      steps to reproduce the issue, screen-shots, logs or
                      similar information to ensure rapid diagnosis of
                      the isuse and subsequent resolution. Support
                      tickets that do not include confirmation of
                      troubleshooting or the information requested will
                      lead to a delay in resolution.
                    </span>
                  </li>
                </ol>
              </li>
            </ol>
          </li>

          <li className="li boldBefore">
            <strong>General Support Service Exclusions</strong>
            <ol className="ol">
              <li className="li">
                <span>
                  No further support will be provided for incidents where
                  there is a failure by Client to implement
                  recommendations to alter behaviour or interactions with
                  the Service, in respect of, or solutions to, incidents
                  that have previously been advised by Appsbroker.{" "}
                </span>
              </li>
              <li className="li">
                <span>
                  No support assistance will be provided when it is
                  apparent that Client is using the Service for a purpose
                  other than for which it was originally intended or
                  designed.
                </span>
              </li>
            </ol>
          </li>

          <li className="li boldBefore">
            <strong>Service Availability</strong>
            <ol className="ol">
              <li className="li">
                <span>
                  Appsbroker will use reasonable efforts to maintain
                  availability to the Service to Client except for
                </span>
              </li>
              <ol className="ol">
                <li className="li">
                  <span>&ldquo;</span>
                  <strong>Scheduled Maintenance</strong>
                  <span>
                    &rdquo;: being maintenance of the Service that has
                    been notified , if Service disruption or other impact
                    to the Service is necessary, Appsbroker will
                    endeavour to provide at least 20 days prior written
                    notice to Client before any such planned maintenance
                    is undertaken. The maintenance window is defined as
                    between 20:00 and 22:00 UK time. Where applicable,
                    changes will be provided for review by Client in a
                    staging environment prior to release to production.{" "}
                  </span>
                </li>
                <li className="li">
                  <span>&ldquo;</span>
                  <strong>Unplanned Maintenance</strong>
                  <span>
                    &rdquo;: being maintenance of any emergency nature to
                    the Service which must be performed in order to avoid
                    adverse consequences for Client. Examples of
                    unplanned maintenance include addressing critical
                    security vulnerabilities or to resolve high priority
                    Service affecting incidents and which could not have
                    reasonably have been performed as Planned
                    Maintenance, in respect of which, Appsbroker has used
                    reasonable endeavours to give Client at least 24
                    hours&rsquo; notice in advance of any service
                    affecting maintenance taking place, together with the
                    timing for the start and completion of the
                    maintenance, the reason for the maintenance and the
                    consequences of the maintenance.
                  </span>
                </li>
              </ol>
            </ol>
          </li>
        </ol>
      </li>
    </ol>

    <div className="spacer"></div>
    <p className="p center">
      <strong>SCHEDULE 2</strong>
    </p>
    <p className="p center">
      <strong>Security </strong>
    </p>

    <p className="p">
      <span>
        Appsbroker warrants that it has an ISO/IEC 27001:2013
        Certification in relation to its operations relating to the
        Services and shall use reasonable efforts to maintain an
        equivalent certification for the duration of the term.
      </span>
    </p>
    <p className="p">
      <span>
        Appsbroker operates the Services within a member state of the
        European Union, EEA (European Economic Area) or United Kingdom,
        but reserves the right to relocate instance(s) where the Services
      </span>
    </p>
    <p className="p">
      <span>
        Each party should notify the other as soon as possible upon
        becoming aware of any material security incident affecting the
        infrastructure used to provide or receive the Services.{" "}
      </span>
    </p>
    <p className="p">
      <span>
        Further information on information security may be provided on
        request; please note that such information is Confidential
        Information of Appsbroker.{" "}
      </span>
    </p>
    <div className="spacer"></div>
    <div className="spacer"></div>
  </div>
)

export default TandCs;